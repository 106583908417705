import PropTypes from 'prop-types';
import React from 'react';

import SkipLink from '~/components/modules/navigation/skip-link';

const Layout = ({ children }) => {
	return (
		<div id="top" className="relative">
			<SkipLink />

			<main id="start-of-content">{children}</main>
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
